import React, { useState } from 'react';
import clsx from 'clsx';

import { Balloon, BristolSkyline, ContentWrap } from '@/components';
import {
  balloon,
  balloon1,
  balloon2,
  balloon3,
  balloon4,
  balloon5,
  balloon6,
  balloon7,
  content,
  highlighted,
  homeHero,
  message,
  skylineMain,
  skylineShadow,
  text,
  word,
} from './HomeHero.module.css';

const Balloons: React.VFC = () => (
  <>
    <Balloon className={clsx(balloon, balloon1)} variant="striped" color="yellow" height="5vw" />
    <Balloon className={clsx(balloon, balloon2)} variant="plain" color="white" height="6.5vw" />
    <Balloon className={clsx(balloon, balloon3)} variant="plain" color="yellow" height="4vw" />
    <Balloon className={clsx(balloon, balloon4)} variant="striped" color="yellow" height="3vw" />
    <Balloon className={clsx(balloon, balloon5)} variant="plain" color="white" height="10vw" />
    <Balloon className={clsx(balloon, balloon6)} variant="striped" color="white" height="6vw" />
    <Balloon className={clsx(balloon, balloon7)} variant="plain" color="yellow" height="5.5vw" />
  </>
);

export const HomeHero: React.VFC = () => {
  const [topWord] = useState<'Creative' | 'Frontend' | 'Digital'>('Creative');
  const [bottomWord] = useState<'Developer' | 'Designer'>('Developer');
  return (
    <div className={homeHero}>
      <ContentWrap className={content}>
        <h1 className={message}>
          <span className={text}>{topWord}</span>
          <span className={clsx(text, highlighted)}>
            <span className={word}>{bottomWord}</span>
          </span>
          <span className={text}>in Bristol</span>
        </h1>
      </ContentWrap>
      <Balloons />
      <BristolSkyline color="homeSkyline" className={skylineMain} />
      <BristolSkyline color="homeSkylineShadow" className={skylineShadow} />
    </div>
  );
};
