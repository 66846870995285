import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';

import { AppStateProvider } from './AppState';
import { ContentWrap, Logo, EmailLink } from '@/components';
import '@/styles/reset.css';
import '@/styles/breakpoints.css';
import '@/styles/colors.css';
import '@/styles/typography.css';
import { logoAndTagline, menu, menuMobile, tagline, topBar, topBarContent } from './Layout.module.css';

const externalLinks = [
  { label: 'linkedIn', to: 'https://www.linkedin.com/in/jonathancjames/' },
  { label: 'twitter', to: 'https://twitter.com/JonJamesDesigns' },
];

const isBrowser = () => typeof window !== 'undefined';

function useDisplayMobileMenu() {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(
    isBrowser() ? window.matchMedia('(max-width: 767px)').matches : false,
  );
  useEffect(() => {
    if (!isBrowser()) return;
    const handler = (e) => setDisplayMobileMenu(e.matches);
    const mediaQuery = window.matchMedia('(max-width: 767px');
    mediaQuery.addEventListener('change', handler);
  }, []);
  return displayMobileMenu;
}

const App: React.FC<PageProps> = ({ children }) => {
  const displayMobileMenu = useDisplayMobileMenu();
  return (
    <AppStateProvider>
      <div className={topBar}>
        <ContentWrap>
          <div className={topBarContent}>
            <div className={logoAndTagline}>
              <Logo />
              <p className={tagline}>software design &#43; development</p>
            </div>
            <div className={displayMobileMenu ? menuMobile : menu}>
              {externalLinks.map(({ label, to }) => (
                <a key={label} href={to} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ))}
              <EmailLink />
            </div>
          </div>
        </ContentWrap>
      </div>
      <main>{children}</main>
    </AppStateProvider>
  );
};

export default App;
