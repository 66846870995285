import React from 'react';
import clsx from 'clsx';

import { contentWrap } from './ContentWrap.module.css';

interface ContentWrap {
  className?: string;
}

export const ContentWrap: React.FC<ContentWrap> = ({ className = '', children }) => {
  const styles = clsx(contentWrap, className);
  return <div className={styles}>{children}</div>;
};
