import React from 'react';

export type AppState = {
  menuIsOpen: boolean;
  toggleMenu(): void;
};

export const AppContext = React.createContext<AppState>(undefined);

export const AppStateProvider: React.FC = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const toggleMenu = React.useCallback(() => setMenuIsOpen((currentState) => !currentState), []);
  // NOTE: we *might* need to memoise this value later
  // Good article on when to memoise context value: http://kcd.im/optimize-context
  const value: AppState = { menuIsOpen, toggleMenu };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
