import React from 'react';

import * as css from './Logo.module.css';

type LogoThemeSetting =
  | {
      bg: 'yellow';
      jon: 'slate';
      james: 'white';
    }
  | {
      bg: 'slate';
      jon: 'yellow';
      james: 'white';
    };

interface LogoProps {
  theme?: 'yellow' | 'slate';
  size?: number;
}

const colorMap: { [key in 'yellow' | 'slate']: LogoThemeSetting } = {
  yellow: {
    bg: 'yellow',
    jon: 'slate',
    james: 'white',
  },
  slate: {
    bg: 'slate',
    jon: 'yellow',
    james: 'white',
  },
};

export const Logo: React.FC<LogoProps> = ({ size = 80, theme = 'yellow' }) => {
  const colors = colorMap[theme];
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path className={css[colors.bg]} d="M100,100H0V0H100Z" />
      <path
        className={css[colors.jon]}
        d="M28,50.92h7.12V77.34a16.74,16.74,0,0,1-1,5.92,11.86,11.86,0,0,1-2.65,4.28,10.91,10.91,0,0,1-4.06,2.59,14.75,14.75,0,0,1-5.14.87,16.94,16.94,0,0,1-4-.43,14.91,14.91,0,0,1-3.19-1.15,12.89,12.89,0,0,1-2.46-1.6,14,14,0,0,1-1.76-1.77L15,80.86a10.4,10.4,0,0,0,1.22,1.27,8.73,8.73,0,0,0,1.56,1.06,9.14,9.14,0,0,0,1.9.73,9.47,9.47,0,0,0,2.26.26,5.58,5.58,0,0,0,4.43-1.81Q28,80.55,28,76.68Zm34.87,7.85a13.76,13.76,0,0,1,0,10.35A12.58,12.58,0,0,1,60,73.25,12.83,12.83,0,0,1,55.77,76a15,15,0,0,1-10.68,0,12.84,12.84,0,0,1-4.23-2.73,12.69,12.69,0,0,1-2.78-4.13,13.76,13.76,0,0,1,0-10.35,12.58,12.58,0,0,1,2.78-4.13,12.71,12.71,0,0,1,4.23-2.74,15,15,0,0,1,10.68,0A12.7,12.7,0,0,1,60,54.64,12.48,12.48,0,0,1,62.82,58.77ZM57,63.94a7.29,7.29,0,0,0-.5-2.76A6.4,6.4,0,0,0,55.13,59,5.94,5.94,0,0,0,53,57.62a6.87,6.87,0,0,0-2.62-.5,6.77,6.77,0,0,0-2.6.5A6.09,6.09,0,0,0,45.74,59a6.4,6.4,0,0,0-1.38,2.15,7.87,7.87,0,0,0,0,5.52,6.55,6.55,0,0,0,1.38,2.16,6.2,6.2,0,0,0,2.08,1.4,6.77,6.77,0,0,0,2.6.5,6.87,6.87,0,0,0,2.62-.5,6.05,6.05,0,0,0,2.09-1.4,6.55,6.55,0,0,0,1.38-2.16A7.25,7.25,0,0,0,57,63.94ZM80,50.83a11.21,11.21,0,0,0-7.22,2.51V51.46H65.88V76.41h6.86V60.25c1.78-2.1,3.87-3.19,5.76-3.19,3.61,0,5.65,2.14,5.65,6.22V76.41H91V62.86C91,55.54,86.6,50.83,80,50.83Z"
      />
      <path
        className={css[colors.james]}
        d="M45.55,81.28A4.07,4.07,0,0,0,42.35,80a5.51,5.51,0,0,0-2.72.67,4.76,4.76,0,0,0-1.92,1.93,6.42,6.42,0,0,0,0,5.79,4.76,4.76,0,0,0,1.92,1.93,5.51,5.51,0,0,0,2.72.67,4.08,4.08,0,0,0,3.34-1.39V91h3V80h-3.1Zm-.71,6.36a2.79,2.79,0,0,1-3.91,0,3.37,3.37,0,0,1,0-4.31,2.79,2.79,0,0,1,3.91,0,3.4,3.4,0,0,1,0,4.31Zm22.27-6.47a4.79,4.79,0,0,1,1.18,3.54V91h-3.1V85.19a2.91,2.91,0,0,0-.52-1.9,1.84,1.84,0,0,0-1.5-.62,2.22,2.22,0,0,0-1.73.7,3.05,3.05,0,0,0-.63,2.09V91h-3.1V85.19c0-1.68-.67-2.52-2-2.52a2.17,2.17,0,0,0-1.71.7,3,3,0,0,0-.64,2.09V91H50.25V80h3v1.43a4,4,0,0,1,1.46-1,5.1,5.1,0,0,1,1.9-.35,4.69,4.69,0,0,1,2,.44,3.62,3.62,0,0,1,1.47,1.3,4.52,4.52,0,0,1,1.66-1.29A5.3,5.3,0,0,1,63.92,80,4.3,4.3,0,0,1,67.11,81.17ZM79.86,82.5a5,5,0,0,0-2-1.87A6,6,0,0,0,75.08,80a6.07,6.07,0,0,0-2.93.7,5.29,5.29,0,0,0-2,2,5.75,5.75,0,0,0,0,5.66,5.15,5.15,0,0,0,2.14,2,7,7,0,0,0,3.2.7,5.52,5.52,0,0,0,4.4-1.72l-1.64-1.79a3.44,3.44,0,0,1-1.2.78,4,4,0,0,1-1.48.25,3.6,3.6,0,0,1-1.78-.4,2.33,2.33,0,0,1-1.06-1.19l7.9-1.53A5.46,5.46,0,0,0,79.86,82.5ZM72.4,85.17a3,3,0,0,1,.78-2.09,2.54,2.54,0,0,1,1.9-.75,2.67,2.67,0,0,1,1.63.49,2.35,2.35,0,0,1,.89,1.33ZM91,87.59a2.78,2.78,0,0,1-.6,1.76,3.77,3.77,0,0,1-1.73,1.21A7.87,7.87,0,0,1,86,91a10.24,10.24,0,0,1-2.6-.33,6.68,6.68,0,0,1-2-.82l1-2.22a6.5,6.5,0,0,0,1.72.74,7.37,7.37,0,0,0,2,.29c1.31,0,2-.32,2-1a.7.7,0,0,0-.53-.66,8.1,8.1,0,0,0-1.65-.33,17.15,17.15,0,0,1-2.16-.46,3.35,3.35,0,0,1-1.48-.91,2.6,2.6,0,0,1-.63-1.87,3,3,0,0,1,.59-1.8,3.8,3.8,0,0,1,1.7-1.22A7.33,7.33,0,0,1,86.53,80a10.78,10.78,0,0,1,2.26.24,6.51,6.51,0,0,1,1.85.69l-1,2.2a6.14,6.14,0,0,0-3.08-.79,3,3,0,0,0-1.48.28.8.8,0,0,0-.5.71.72.72,0,0,0,.54.7,9.66,9.66,0,0,0,1.7.37,18.19,18.19,0,0,1,2.15.47,3.23,3.23,0,0,1,1.44.9A2.58,2.58,0,0,1,91,87.59Z"
      />
    </svg>
  );
};
